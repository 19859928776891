import {
  getNonprofitAccountingSettings,
  getNonprofitAccountingSpecification,
  getOrganizationPspSubWallet,
  getOrganizationPspWallet,
  getPlatformSpecifications,
} from 'api'
import { useApiError, useSelector } from 'hooks'
import { NonprofitContext, OrganizationContext } from 'providers'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { selectSettings, setSettings } from 'state'
import { iriToId } from 'utils'

import * as Type from './type'
import { ISetting } from './type'

const useSettings = (): Type.IUseSettings => {
  const settings = useSelector(selectSettings)
  const { organization } = useContext(OrganizationContext)
  const { nonprofit } = useContext(NonprofitContext)
  const dispatch = useDispatch()
  const apiError = useApiError()

  const getSetting = async (
    setting: Type.ESetting,
    refreshData = false,
    errorHandler?:
      | boolean
      | {
          allowedErrorCodes?: Array<number>
          callback?: () => void
        }
  ): Promise<Type.ISetting> => {
    // Setting already exists in the store
    if (settings[setting] !== undefined && !refreshData) {
      return settings[setting] as Type.ISetting
    }
    const newSetting: { [key: string]: ISetting } = {}
    try {
      // Fetch setting and update store
      switch (setting) {
        case Type.ESetting.ACCOUNTING: {
          const nonprofitAccountingSettings =
            await getNonprofitAccountingSettings(nonprofit.id)
          const accountingSpecification =
            await getNonprofitAccountingSpecification(nonprofit.id)
          newSetting[Type.ESetting.ACCOUNTING] = {
            ...nonprofitAccountingSettings,
            ...accountingSpecification,
          }
          break
        }
        case Type.ESetting.PLATFORM_SPECIFICATIONS: {
          newSetting[Type.ESetting.PLATFORM_SPECIFICATIONS] =
            await getPlatformSpecifications(iriToId(nonprofit.platform))
          break
        }
        case Type.ESetting.PSP_SUB_WALLET: {
          newSetting[Type.ESetting.PSP_SUB_WALLET] =
            await getOrganizationPspSubWallet(organization.id)
          break
        }
        case Type.ESetting.PSP_WALLET: {
          newSetting[Type.ESetting.PSP_WALLET] = await getOrganizationPspWallet(
            organization.id
          )
          break
        }
      }
    } catch (error: unknown) {
      if (errorHandler !== false) {
        const allowedErrorCodes =
          errorHandler === true || errorHandler?.allowedErrorCodes === undefined
            ? []
            : errorHandler.allowedErrorCodes

        apiError.handleApiError(error, {
          allowedErrorCodes,
        })
        newSetting[setting] = null
        if (errorHandler !== true && errorHandler?.callback) {
          errorHandler?.callback()
        }
      }
    }
    const settingsUpdated = {
      ...settings,
      ...newSetting,
    }
    dispatch(setSettings(settingsUpdated))
    return settingsUpdated[setting] as ISetting
  }

  return {
    getSetting,
  }
}

export default useSettings
