import * as Sentry from '@sentry/react'
import {
  getCrmPersonRoles,
  getCurrentAdmin,
  getMenuItems,
  getOrganizationCollectSpecifications,
  getOrganizationCrmSpecifications,
  getOrganizationLegacyDetails,
  getOrganizationSubscription,
  getOrganizationSubscriptionSpecifications,
  getSupportMessages,
} from 'api'
import { useApiError, useSelector, useStoreI18n } from 'hooks'
import { NonprofitContext, OrganizationContext } from 'providers'
import { useContext, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import {
  selectOrganization,
  selectPerson,
  setOrganization,
  setPerson,
  setPersonGlobalRoles,
  setPersonRoles,
} from 'state'
import { setMenu } from 'state/Menu/slice'
import { IStoreOrganization } from 'state/type'

import * as Type from './type'

const UseStore = (): Type.IUseStore => {
  const dispatch = useDispatch()
  const params = useParams<Type.IParams>()
  const storeOrganization = useSelector(selectOrganization)
  const storePerson = useSelector(selectPerson)
  const storeI18n = useStoreI18n()
  const apiError = useApiError()

  const { organization } = useContext(OrganizationContext)
  const { nonprofit } = useContext(NonprofitContext)

  const isLoaded = !!(storeOrganization.id && storePerson.contactId)
  const [isLoading, setIsLoading] = useState(false)

  const initialize = async () => {
    if (!isLoaded && !isLoading && organization.id) {
      setIsLoading(true)

      // Person
      const person = await getCurrentAdmin()

      // Parallel API requests to increase performance
      Promise.all([
        // 0. Organization collect specifications
        Promise.resolve(getOrganizationCollectSpecifications(organization.id)),
        // 1. Organization CRM specifications
        Promise.resolve(getOrganizationCrmSpecifications(organization.id)),
        // 2. Organization subscription specifications
        Promise.resolve(
          getOrganizationSubscriptionSpecifications(organization.id)
        ),
        // 3. Organization subscription and redirection logic
        Promise.resolve(getOrganizationSubscription(organization.id))
          .then((result) => result)
          .catch(() => null),
        // 4. Person roles
        Promise.resolve(getCrmPersonRoles(person.contactId, organization.id)),
        // 5. Support message
        Promise.resolve(getSupportMessages()),
        // 6. Menu Items
        Promise.resolve(getMenuItems(organization.id)),
        // 7. Legacy details
        Promise.resolve(getOrganizationLegacyDetails(organization.id)),
      ]).then((values) => {
        const organizationStorage: IStoreOrganization = {
          ...organization,
          nonprofit,
          brand: organization.brand,
          parent: organization.parent,
          isNonprofitMainGroup: organization.isNonprofitMainGroup,
          collectSpecifications: values[0],
          crmSpecifications: values[1],
          subscriptionSpecifications: values[2],
          subscription: values[3],
          url: organization.url,
          phoneNumber: organization.phoneNumber,
          createdAt: organization.createdAt,
          hasIndependentCommunity: values[7].hasIndependentCommunity,
          hasIndependentSite: values[7].hasIndependentSite,
          idOld: parseInt(params.organizationId || ''),
          iri: organization['@id'],
          logo: {
            url: organization.logoUrl,
            height: values[7].logo?.height,
            width: values[7].logo?.width,
          },

          slug: values[7].slug,
        }

        // Person roles
        const personRoles = values[4]

        // Support message
        const supportMessages = values[5]

        // menuItems
        const menuItems = values[6]

        // Initialize I18n store
        storeI18n.initialize(supportMessages, organizationStorage)

        // Set sentry user
        Sentry.setUser({
          email: person.email,
          id: person.contactId,
          username: `${person.firstName} ${person.lastName}`,
        })

        // Set store
        // TODO: React 18 unstable_batchedUpdates: https://github.com/reactwg/react-18/discussions/21
        unstable_batchedUpdates(() => {
          dispatch(setMenu(menuItems))
          dispatch(setOrganization(organizationStorage))
          dispatch(
            setPerson({ ...person, isAdminMaster: personRoles.isAdminMaster })
          )
          dispatch(
            setPersonRoles({
              ...person.roles,
              [organization.id]: personRoles.roles,
            })
          )
          dispatch(
            setPersonGlobalRoles({
              ...person.globalRoles,
              [organization.id]: personRoles.globalRoles,
            })
          )
        })
        setIsLoading(false)
      })
    }
  }

  const initializeAdminMaster = async () => {
    if (storePerson.isAdminMaster !== undefined) {
      return
    }

    const person = await getCurrentAdmin()
    try {
      await getCrmPersonRoles(person.contactId)
      dispatch(setPerson({ ...storePerson, isAdminMaster: true }))
    } catch (error: unknown) {
      dispatch(setPerson({ ...storePerson, isAdminMaster: false }))
      apiError.handleApiError(error, { allowedErrorCodes: [400] })
    }
  }

  return { isLoaded, initialize, initializeAdminMaster }
}

export default UseStore
