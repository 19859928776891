import { IApiBankingSettings, IApiOrganizationPspWallet } from 'api/type'
import { ESetting, TGetSetting } from 'hooks/useSettings/type'
import { IOrganization } from 'providers/Organization/type'

import * as Type from './type'
import { ERefusalReason } from './type'

export const getRefusalReason = (status: number) => {
  switch (status) {
    case 403:
      return Type.ERefusalReason.UNAUTHORIZED
    case 404:
      return Type.ERefusalReason.SETTING
    default:
      return Type.ERefusalReason.OTHER
  }
}

export const getPspWalletRefusalReason = (
  pspWallet: IApiOrganizationPspWallet
): Type.ERefusalReason | null => {
  if (pspWallet.isBlocked) {
    return ERefusalReason.PSP_WALLET_BLOCKED
  }
  if (pspWallet.isSuspended) {
    return ERefusalReason.PSP_WALLET_SUSPENDED
  }
  return null
}

export const getBankAccountsAccess = async (
  organization: IOrganization,
  getSetting: TGetSetting
) => {
  if (organization.isLegalIndependent) {
    return true
  }

  const bankingSettings = (await getSetting(ESetting.BANKING_SETTINGS, false, {
    allowedErrorCodes: [403, 404],
  })) as IApiBankingSettings

  return !!bankingSettings?.ownBankAccounts
}
