import { IApiWorkflowAction } from 'api/type'
import { EProAccountUserStatusFilter } from 'pages/ProAccount/Administrators/type'

import { IProAccountAccount, IProAccountConsent } from '../type'
import { IProAccountUser } from '../user/type'

export interface IApiProAccountAccountMember {
  '@id': string
  '@type': string
  id: string
  user?: IProAccountUser
  account?: IProAccountAccount
  status: EProAccountAccountMembershipStatus
  consent?: null | IProAccountConsent
  swanId: string
  ['workflow:actions']?: IApiWorkflowAction<EProAccountAccounetMembershipAction>[]
}

export interface IApiProAccountAccountBindingErrors {
  name: string
  currentValue: string
  expectedValue: string
  isOnError: boolean
}

export type TBindingErrors = {
  [key: string]: Omit<IApiProAccountAccountBindingErrors, 'name'>
}

export interface IApiProAccountAccountMembersFilters {
  status?: EProAccountUserStatusFilter[] | null
  'status!'?: EProAccountUserStatusFilter
  itemsPerPage?: number
}

export enum EProAccountAccounetMembershipAction {
  REQUEST_CONSENT = 'request_consent',
  FIX_MISSING_DATA = 'fix_missing_data',
  DISABLED = 'disabled',
  UPDATE_FROM_REMOTE = 'update_from_remote',
  SEND_INVITATION_EMAIL = 'send_invitation_email',
  SEND_IDENTIFICATION_NOT_VERIFIED_EMAIL = 'send_identification_not_verified_email',
}

export enum EProAccountAccountMembershipUserAction {
  SEND_IDENTIFICATION_NOT_VERIFIED_EMAIL = 'send_identification_not_verified_email',
}

export enum EProAccountAccountMembershipStatus {
  NEW = 'NEW',
  CONSENT_PENDING = 'CONSENT_PENDING',
  INVITATION_SENT = 'INVITATION_SENT',
  ENABLED = 'ENABLED',
  BINDING_USER_ERROR = 'BINDING_USER_ERROR',
  SUSPENDED = 'SUSPENDED',
  DISABLED = 'DISABLED',
}

export enum EProAccountAccountUserStatus {
  VERIFIED = 'VERIFIED',
  IDENTIFICATION_FAILED = 'IDENTIFICATION_FAILED',
  IDENTIFICATION_NOT_VERIFIED = 'IDENTIFICATION_NOT_VERIFIED',
  IDENTIFICATION_NOT_STARTED = 'IDENTIFICATION_NOT_STARTED',
}
